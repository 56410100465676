import { ColorDefs } from './colorDefs';

export const Colors = {
  appBackgroundColor: ColorDefs.neutralBlack,
  appColor: ColorDefs.neutralWhite,

  // Header
  menuLogoColor: ColorDefs.robinsEggBlue,
  accountLogoColor: ColorDefs.neutralBlack,
  homeHeaderColor: ColorDefs.robinsEggBlue,
  accountHeaderColor: ColorDefs.robinsEggBlue,
  homeHeaderBackgroundColor: ColorDefs.neutralBlack,
  contentHeaderBackgroundColor: ColorDefs.lightGreen,
  mentorshipHeaderBackgroundColor: ColorDefs.elm,
  submissionsGuidelinesHeaderBackgroundColor: ColorDefs.apricot,
  mentorshipHeaderColor: ColorDefs.white,
  submissionsStartHeaderBackgroundColor: ColorDefs.apricot,
  submissionsStartHeaderColor: ColorDefs.neutralBlack,
  infoHeaderColor: ColorDefs.neutralWhite,
  infoHeaderBackgroundColor: ColorDefs.casal,
  prizeHeaderColor: ColorDefs.neutralWhite,
  prizeHeaderBackgroundColor: ColorDefs.ferra,
  earthHeaderColor: ColorDefs.neutralBlack,
  earthHeaderBackgroundColor: ColorDefs.robinsEggBlue,
  termsAndConditionsHeaderColor: ColorDefs.neutralWhite,
  termsAndConditionsHeaderBackgroundColor: ColorDefs.elm,
  earthPrizeTermsAndConditionsHeaderBackgroundColor: ColorDefs.redSand,
  cookiePolicyHeaderColor: ColorDefs.neutralBlack,
  cookiePolicyHeaderBackgroundColor: ColorDefs.apricot,
  privacyPolicyHeaderColor: ColorDefs.neutralWhite,
  privacyPolicyHeaderBackgroundColor: ColorDefs.paradiso,
  copyrightPolicyHeaderColor: ColorDefs.neutralWhite,
  copyrightPolicyHeaderBackgroundColor: ColorDefs.ferra,
  participantSubmissionPackageHeaderColor: ColorDefs.neutralBlack,
  participantSubmissionPackageHeaderBackgroundColor: ColorDefs.apricot,
  faqHeaderColor: '#77484C',
  faqHeaderBackgroundColor: ColorDefs.springWood,
  mentorsFaqHeaderColor: ColorDefs.neutralWhite,
  mentorsFaqHeaderBackgroundColor: ColorDefs.casal,
  codeOfConductHeaderColor: ColorDefs.neutralWhite,
  codeOfConductHeaderBackgroundColor: ColorDefs.matrix,
  ceremonyRegistrationHeaderColor: ColorDefs.neutralBlack,
  ceremonyRegistrationHeaderBackgroundColor: ColorDefs.aquaIsland,
  ceremonyHeaderColor: ColorDefs.neutralBlack,
  ceremonyHeaderBackgroundColor: '#afe0d7',
  scholarsHeaderColor: ColorDefs.neutralBlack,
  scholarsHeaderBackgroundColor: ColorDefs.apricot,
  finalistsHeaderColor: ColorDefs.neutralBlack,
  finalistsHeaderBackgroundColor: ColorDefs.chestnutRose,
  prize2022HeaderBackgroundColor: '#724B4E',
  prize2022HeaderColor: ColorDefs.neutralWhite,
  prize2022HeaderLoginColor: ColorDefs.neutralBlack,
  courseHeaderColor: ColorDefs.neutralBlack,
  menuButtonColorOpened: ColorDefs.robinsEggBlue,
  menuButtonBackground: ColorDefs.neutralWhite,
  menuLinkColor: ColorDefs.neutralBlack,
  mobileMenuBackground: ColorDefs.neutralBlack,
  accountMobileMenuBackground: ColorDefs.robinsEggBlue,
  menuLinksWrapperColor: ColorDefs.robinsEggBlue,
  accountMenuLinksWrapperColor: ColorDefs.neutralBlack,
  menuLinksWrapperActionButtonIconColor: ColorDefs.neutralBlack,
  accountMenuLinksWrapperActionButtonIconColor: ColorDefs.robinsEggBlue,
  bellCounterBackgroundColor: ColorDefs.neutralWhite,
  bellCounterColor: ColorDefs.neutralBlack,

  // Footer
  footerBackgroundColor: ColorDefs.neutralBlack,
  footerActionButtonBackgroundColor: ColorDefs.neutralWhite,
  footerActionButtonColor: ColorDefs.neutralBlack,
  footerBottomActionButtonBackgroundColor: ColorDefs.neutralBlack,
  footerBottomActionButtonColor: ColorDefs.neutralWhite,

  // Socials
  socialBackgroundColor: ColorDefs.neutralWhite,
  socialColor: ColorDefs.neutralBlack,
  socialBackgroundColorInverted: ColorDefs.neutralBlack,
  socialColorInverted: ColorDefs.neutralWhite,

  // Cookies
  cookiesWrapperBackgroundColor: ColorDefs.aquaIsland,
  cookiesWrapperColor: ColorDefs.neutralBlack,

  // Typography
  typographyColor: ColorDefs.neutralWhite,

  // Buttons
  actionButtonBackgroundColor: ColorDefs.robinsEggBlue,

  // Inputs
  inputBorder: ColorDefs.neutralWhite,
  inputPlaceholderColor: ColorDefs.neutralWhite,

  // Section
  sectionNameWrapperBottomBorderColor: ColorDefs.neutralWhite,
  sectionActionButtonBackgroundColor: ColorDefs.neutralWhite,
  sectionActionButtonColor: ColorDefs.neutralBlack,

  // Tab
  tabBackgroundColor: ColorDefs.robinsEggBlue,
  tabColor: ColorDefs.neutralBlack,
  tabActionButtonBackgroundColor: ColorDefs.neutralBlack,
  tabActionButtonColor: ColorDefs.robinsEggBlue,

  // Carousel
  dotsColor: ColorDefs.athensGray,
  dotsActiveColor: ColorDefs.neutralBlack,

  // Home view
  homeHeroWrapperColor: ColorDefs.robinsEggBlue,
  homeSection3WrapperBackgroundColor: ColorDefs.robinsEggBlue,
  homeSection3WrapperColor: ColorDefs.neutralBlack,
  homeSection3ActionButtonColor: ColorDefs.robinsEggBlue,
  homeSection3ActionButtonBackgroundColor: ColorDefs.neutralBlack,
  homeSection4WrapperBackgroundColor: ColorDefs.ferra,
  homeSection4ContentColor: ColorDefs.apricot,
  homeSection4ContentSocialsBackgroundColor: ColorDefs.apricot,
  homeSection4ContentSocialsColor: ColorDefs.ferra,
  homeSection4ActionButtonColor: ColorDefs.ferra,
  homeSection4ActionButtonBackgroundColor: ColorDefs.apricot,
  homeSection6WrapperBackgroundColor: ColorDefs.paradiso,
  homeSection6WrapperColor: ColorDefs.aquaIsland,
  homeSection6ActionButtonColor: ColorDefs.paradiso,
  homeSection6ActionButtonBackgroundColor: ColorDefs.aquaIsland,

  // Info view
  infoHeroWrapperColor: ColorDefs.neutralWhite,
  infoHeroWrapperBackgroundColor: ColorDefs.casal,
  infoSection1WrapperBackgroundColor: ColorDefs.robinsEggBlue,
  infoSection1WrapperColor: ColorDefs.neutralBlack,
  infoSection3WrapperBackgroundColor: ColorDefs.elm,
  infoSection3WrapperColor: ColorDefs.aquaIsland,
  infoSection5WrapperColor: ColorDefs.neutralWhite,
  infoSection5WrapperBackgroundColor: ColorDefs.casal,
  infoSection6WrapperColor: ColorDefs.robinsEggBlue,
  infoSection7WrapperColor: ColorDefs.neutralBlack,
  infoSection7WrapperBackgroundColor: ColorDefs.springWood,
  infoSection8WrapperBackgroundColor: ColorDefs.robinsEggBlue,
  infoSection8WrapperColor: ColorDefs.neutralBlack,
  infoSection9WrapperBackgroundColor: ColorDefs.elm,
  infoSection9WrapperColor: ColorDefs.neutralWhite,

  // Prize view
  prizeHeroWrapperColor: ColorDefs.apricot,
  prizeHeroWrapperBackgroundColor: ColorDefs.ferra,
  prizeSection2WrapperColor: ColorDefs.neutralWhite,
  prizeSection2OrangeTabColor: ColorDefs.apricot,
  prizeSection2CoralTabColor: ColorDefs.chestnutRose,
  prizeSection2RedTabColor: ColorDefs.matrix,
  prizeSection4WrapperBackgroundColor: ColorDefs.chestnutRose,
  prizeSection4WrapperColor: ColorDefs.neutralWhite,
  prizeSection6WrapperBackgroundColor: ColorDefs.springWood,
  prizeSection6WrapperColor: ColorDefs.ferra,

  // Earth view
  earthHeroWrapperBackgroundColor: ColorDefs.robinsEggBlue,
  earthHeroWrapperColor: ColorDefs.neutralBlack,
  earthSection2WrapperBackgroundColor: ColorDefs.aquaIsland,
  earthSection2WrapperColor: ColorDefs.casal,

  // Terms And Conditions
  TermsAndConditionsWrapperBackgroundColor: ColorDefs.elm,

  // Cookie Policy
  CookiePolicyWrapperBackgroundColor: ColorDefs.apricot,
  CookiePolicyWrapperColor: ColorDefs.ferra,

  // Privacy Policy
  PrivacyPolicyWrapperBackgroundColor: ColorDefs.paradiso,
  PrivacyPolicyWrapperColor: ColorDefs.aquaIsland,

  // Copyright Policy
  CopyrightPolicyWrapperBackgroundColor: ColorDefs.ferra,
  CopyrightPolicyWrapperColor: ColorDefs.apricot,

  // Code of Conduct
  CodeOfConductWrapperBackgroundColor: ColorDefs.matrix,
  CodeOfConductWrapperColor: ColorDefs.neutralWhite,

  // Participant Submission Package
  ParticipantSubmissionPackageWrapperBackgroundColor: ColorDefs.apricot,
  ParticipantSubmissionPackageWrapperColor: ColorDefs.neutralBlack,

  // Ambassadors
  AmbassadorsWrapperBackgroundColor: ColorDefs.neutralBlack,
  AmbassadorsWrapperColor: ColorDefs.robinsEggBlue,

  // Scholars
  ScholarsWrapperBackgroundColor: ColorDefs.apricot,
  ScholarsWrapperColor: ColorDefs.neutralBlack,

  // Scholars
  FinalistsWrapperBackgroundColor: ColorDefs.chestnutRose,
  FinalistsWrapperColor: ColorDefs.neutralBlack,

  // The Earth Prize Terms And Conditions
  EarthPrizeTermsAndConditionsWrapperBackgroundColor: ColorDefs.redSand,
  EarthPrizeTermsAndConditionsWrapperColor: ColorDefs.neutralBlack,

  // Lesson
  LessonMenuBackgroundColor: ColorDefs.greenCyan,
  LessonBackgroundColor: ColorDefs.white,
};
