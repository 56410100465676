import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Colors } from 'styles/colors';

interface IActionButton {
  onClick?: () => void;
  isSmall?: boolean;
  disabled?: boolean;
  background?: string;
  color?: string;
  style?: object;
  fontSize?: string;
  text?: string;
  children?: React.ReactNode;
}

export const ActionButton: FunctionComponent<IActionButton> = ({
  children,
  onClick,
  isSmall,
  disabled,
  background,
  color,
  style,
  fontSize,
  text,
}) => {
  return (
    <ActionButtonStyled
      background={background || Colors.actionButtonBackgroundColor}
      color={color}
      disabled={disabled}
      isSmall={isSmall}
      onClick={onClick}
      style={style}
      fontSize={fontSize}
      text={text}
    >
      {children}
    </ActionButtonStyled>
  );
};

export const ActionButtonStyled = styled.button<IActionButton>`
  ${({
    background,
    fontSize,
    color,
    disabled,
    isSmall,
    text = 'uppercase',
    theme: { Consts },
  }) => css`
    font-size: ${isSmall ? '24px' : `${fontSize || '16px'}`};
    text-transform: ${text};
    height: ${isSmall ? `36px` : `80px`};
    background-color: ${background};
    font-family: ${Consts.defaultFontFamily};
    font-weight: bold;
    padding: ${isSmall ? `0 21px` : `0 15px`};
    border: 0;
    color: ${color} !important;
    border-radius: 40px 40px 40px 3px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    position: relative;
    z-index: 2;
    min-width: ${isSmall ? `60px` : `140px`};
    cursor: ${disabled ? 'auto' : `pointer`};
    opacity: ${disabled ? `0.5` : `1`};
    width: min-content;
    flex-shrink: 0;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: ${isSmall ? `12px` : `12px`};
      height: ${isSmall ? `24px` : `40px`};
      min-width: ${isSmall ? `25px` : `90px`};
      padding: 0 14px;
      border-radius: 50px 50px 50px 3px;
    }
  `}
`;
