// To get color name use https://chir.ag/projects/name-that-color/#F3F4F8

export const ColorDefs = {
  transparent: 'transparent',
  neutralBlack: '#000000',
  neutralWhite: '#F7F7F0',
  white: '#FFFFFF',
  athensGray: '#F3F4F8',
  robinsEggBlue: '#00B7BC',
  ferra: '#77484C',
  apricot: '#ED9F6D',
  paradiso: '#317E88',
  aquaIsland: '#AEDFD6',
  casal: '#2D5B66',
  elm: '#1D8576',
  springWood: '#F7F7F0',
  chestnutRose: '#CD6E62',
  matrix: '#A95C5C',
  lightGreen: '#AEE0D7',
  redSand: '#CD6F63',
  greenCyan: '#B0DFD6',
};
