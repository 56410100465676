import { Colors } from './colors';
import { Consts } from './consts';
import { ColorDefs } from './colorDefs';

export const Theme = {
  Colors,
  Consts,
  ColorDefs,
};

export type TThemeType = typeof Theme;
