import React, { FunctionComponent, useEffect, useState } from 'react';

import styled, { css, ThemeProvider } from 'styled-components';
import { GlobalFonts } from 'styles/GlobalFonts';
import { GlobalStyles } from 'styles/GlobalStyles';
import { Cookies } from 'components/Cookies/Cookies';
import { checkCookie, setCookie } from 'utils';
import { COOKIES_AGREEMENT, COOKIES_LIFETIME_IN_DAYS } from 'consts';
import { Theme } from 'styles/theme';
import Head from 'next/head';

import 'react-datepicker/dist/react-datepicker.css';

interface ILayout {
  children: React.ReactNode;
  id?: string;
}

export const Layout: FunctionComponent<ILayout> = ({ children, id }) => {
  const [canTrack, setTracking] = useState(false);
  const [isCookieBannerShown, showCookieBanner] = useState(true);

  const checkTracking = () => {
    setTracking(checkCookie(COOKIES_AGREEMENT));
  };

  const handleAcceptClick = () => {
    try {
      setCookie(COOKIES_AGREEMENT, 'true', COOKIES_LIFETIME_IN_DAYS);
      showCookieBanner(false);
      checkCookie(COOKIES_AGREEMENT);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleDeclineClick = () => {
    setTracking(false);
    setCookie(COOKIES_AGREEMENT, 'false', COOKIES_LIFETIME_IN_DAYS);
    showCookieBanner(false);
  };

  useEffect(() => {
    checkTracking();
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <LayoutWrapper id={id}>
        <Head>
          <script
            src="https://www.amcharts.com/lib/3/ammap.js"
            type="text/javascript"
          />
          <script
            src="https://www.amcharts.com/lib/3/maps/js/worldHigh.js"
            type="text/javascript"
          />
          <script
            src="https://www.amcharts.com/lib/3/themes/dark.js"
            type="text/javascript"
          />
        </Head>
        <GlobalFonts />
        <GlobalStyles />
        {children}
        {!canTrack && isCookieBannerShown && (
          <Cookies
            handleAcceptClick={handleAcceptClick}
            handleDeclineClick={handleDeclineClick}
          />
        )}
      </LayoutWrapper>
    </ThemeProvider>
  );
};

export const LayoutWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    width: 100%;
    margin: 0 auto;

    @media (max-width: ${Consts.mobileBreakPoint}) {
      padding: 0;
      min-height: calc(100vh - 100px);
    }
  `}
`;
