import styled, { css } from 'styled-components';

const defaultTypographyStyles = css`
  ${({ theme: { Consts, Colors } }) => css`
    font-family: ${Consts.defaultFontFamily};
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0;
    text-align: left;
    color: ${Colors.typographyColor};
  `}
`;

export const H1 = styled.h1`
  ${({ theme: { Consts } }) => css`
    ${defaultTypographyStyles};
    font-size: 50px;
    line-height: 66px;
    font-weight: bold;
    font-family: ${Consts.defaultFontFamily};
    @media (max-width: ${Consts.mobileBreakPoint}) {
      font-size: 28px;
      line-height: 35px;
      margin: 0;
    }
  `}
`;

export const H2 = styled.h2<{ color?: string }>`
  ${({ color, theme: { Consts, Colors } }) => css`
    ${defaultTypographyStyles};
    font-size: 50px;
    font-family: ${Consts.defaultFontFamily};
    color: ${color || Colors.typographyColor};
    font-weight: bold;
    margin: 0 0 10px;
    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 25px;
    }
  `}
`;

export const H3 = styled.h3<{ color?: string }>`
  ${({ color, theme: { Consts, Colors } }) => css`
    ${defaultTypographyStyles};
    font-size: 32px;
    font-family: ${Consts.defaultFontFamily};
    color: ${color ? color : Colors.typographyColor};
    font-weight: bold;
    margin: 0 0 10px;
    @media (min-width: ${Consts.tabletBreakPoint}) {
      font-size: 18px;
    }
  `}
`;

export const H4 = styled.h4`
  ${({ theme: { Consts } }) => css`
    ${defaultTypographyStyles};
    font-size: 28px;
    font-family: ${Consts.defaultFontFamily};
    font-weight: bold;
    margin: 0 0 10px;
    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 15px;
    }
  `}
`;

export const Subtitle = styled.p`
  ${({ theme: { Consts } }) => css`
    ${defaultTypographyStyles};
    font-size: 42px;
    font-family: ${Consts.defaultFontFamily};
    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 25px;
    }
  `}
`;

export const Text = styled.p<{ color?: string; align?: string }>`
  ${({ theme: { Consts }, color, align }) => css`
    ${defaultTypographyStyles};
    font-size: 18px;
    color: ${color};
    text-align: ${align};

    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 14px;
    }

    a {
      color: #00b7bd;
    }
  `}
`;

export const TextSmall = styled.p`
  ${({ theme: { Consts } }) => css`
    ${defaultTypographyStyles};
    font-size: 16px;
    font-weight: 200;
    @media (max-width: ${Consts.tabletBreakPoint}) {
      font-size: 12px;
    }
  `}
`;
