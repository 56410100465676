export const Consts = {
  defaultFontFamily: 'HeroNew, sans-serif',
  mokokoFontFamily: 'Mokoko, sans-serif',

  defaultFontSize: '22px',
  layoutWidth: '1220px',
  layoutWidthTablet: '768px',
  headerWidth: '1210px',
  footerWidth: '1210px',
  mobileBreakPoint: '500px',
  smallTabletBreakPoint: '900px',
  tabletBreakPoint: '1200px',
  desktopBreakPoint: '1201px',
};
