import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`${({ theme }) => css`
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  :root {
    --app-height: 100%;
  }

  body,
  html {
    background: ${theme.Colors.appBackgroundColor};
    color: ${theme.Colors.appColor};
    font-family: ${theme.Consts.defaultFontFamily};
    font-size: ${theme.Consts.defaultFontSize};
    width: 100vw;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;

    &.locked {
      overflow: hidden;
    }
  }

  input {
    font-family: ${theme.Consts.defaultFontFamily};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
  }

  .amcharts-chart-div {
    a {
      display: none !important;
    }
  }

  a {
    text-decoration: none;
    color: ${theme.Colors.appColor};
  }
  #submissions {
    background: ${theme.ColorDefs.apricot};
  }
  #contentSection {
    background: ${theme.ColorDefs.white};
  }
  #contentSection1 {
    background: ${theme.ColorDefs.lightGreen};
  }
  #mentorshipSection1 {
    background: ${theme.ColorDefs.elm};
  }
  #mentorshipSection {
    background: ${theme.ColorDefs.white};
  }
  .slick-slider {
    .slick-arrow {
      &:before {
        color: #00b7bc;
        opacity: 1;
      }
    }

    .slick-disabled {
      opacity: 0;
    }

    .slick-dots {
      bottom: 0;

      > li {
        margin: -1px;

        > button {
          &::before {
            font-size: 14px;
            color: ${theme.Colors.dotsColor};
          }
        }

        @media (max-width: ${theme.Consts.mobileBreakPoint}) {
          > button {
            &::before {
              font-size: 12px;
            }
          }
        }

        &.slick-active {
          > button {
            &::before {
              color: ${theme.Colors.dotsActiveColor} !important;
            }
          }
        }
      }
    }
  }
`}`;
