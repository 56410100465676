import { createGlobalStyle } from 'styled-components';

export const GlobalFonts = createGlobalStyle`

   @font-face {
     font-family: 'HeroNew';
     src: url('/fonts/HeroNewBold.otf') format('opentype');
     font-weight: 900;
   }

   @font-face {
     font-family: 'HeroNew';
     src: url('/fonts/HeroNewExtraBold.otf') format('opentype');
     font-weight: bold;
   }

   @font-face {
     font-family: 'HeroNew';
     src: url('/fonts/HeroNewRegular.otf') format('opentype');
     font-weight: normal;
   }

   @font-face {
     font-family: 'HeroNew';
     src: url('/fonts/HeroNewLight.otf') format('opentype');
     font-weight: 200;
   }

   @font-face {
     font-family: 'Mokoko';
     src: url('/fonts/MokokoXBd.otf') format('opentype');
     font-weight: bold;
   }

   @font-face {
     font-family: 'Mokoko';
     src: url('/fonts/MokokoRg.otf') format('opentype');
     font-weight: normal;
   }

   @font-face {
     font-family: 'Mokoko';
     src: url('/fonts/MokokoLt.otf') format('opentype');
     font-weight: 200;
   }
`;
